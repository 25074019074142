
import Vue from 'vue'
import { VForm, VMenu } from '../types/form'
import Loading from '../components/common/loading.vue'
import { useAppStore } from '@/store/app'
import { rest } from 'lodash'

export default Vue.extend({
  name: 'SelfRegistry',
  components: { Loading },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      signer: {
        id: '',
        email: '',
        name: '',
        lastname: '',
        historyNumber: '',
        birthday: '',
        gender: '',
        phone: '',
        address: '',
        preferredLang: ''
      },
      email: '',
      historyNumber: '',
      logoUrl: '',
      color: '',
      date: null,
      activePicker: null,
      menu: false,
      rules: {
        required: (value: string) => !!value || this.$t('formValidation.required'),
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('formValidation.invalidEmail')
        },
        date: (value: string) => {
          const t = new Date(value)
          // @ts-expect-error: blah
          return (t instanceof Date && isFinite(t)) || this.$t('formValidation.invalidDate')
        }
      },
      loading: false,
      height: 0,
      width: 0,
      auto: false
    }
  },
  beforeMount () {
    this.email = ((this.$route.query.id ?? '') as string).trim()
    if (!this.email) {
      this.$router.push({ path: `/${this.$route.params.clientId}/expired/${this.$route.params.ref}` })
      return
    }
    this.$router.push({ query: {} })
    this.getSignerDetails()
  },
  mounted () {
    this.color = this.app.primaryColor
    this.logoUrl = this.app.selectedClient?.logo ?? ''
    const height = (this.$refs.container as HTMLElement)?.clientHeight + 70
    const width = (this.$refs.container as HTMLElement)?.clientWidth
    this.height = height
    this.width = width
  },
  watch: {
    date (val) {
      this.signer.birthday = this.formatDate(this.date) ?? ''
    },
    $route (to, from) {
      this.color = this.app.primaryColor
      this.$vuetify.theme.currentTheme.primary = this.app.primaryColor
    },
    'app.primaryColor' (newValue, oldValue) {
      this.color = this.app.primaryColor
    }
  },
  methods: {
    async getSignerDetails () {
      const clientId = this.$route.params.clientId
      const reference = this.$route.params.ref
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${clientId}/signers/${this.email}/registry/${reference}`)
      if (res.status !== 200) {
        this.$router.push({ path: `/${this.$route.params.clientId}/expired/${this.$route.params.ref}` })
        return
      }
      const data = await res.json()

      this.app.selectClient({
        id: this.$route.params.clientId,
        logo: data.client.logo,
        color: data.client.color,
        name: data.client.name
      })

      this.historyNumber = data.signer.historyNumber.trim()
      this.signer.historyNumber = data.signer.historyNumber
      this.signer.email = this.email
    },
    async addSigner () {
      if (!await (this.$refs.form as VForm).validate()) {
        return
      }

      this.loading = true

      const formData = {
        id: this.signer.id?.trim(),
        name: this.signer.name?.trim(),
        lastname: this.signer.lastname?.trim(),
        email: this.email,
        historyNumber: this.historyNumber,
        birthday: this.signer.birthday,
        gender: this.signer.gender,
        phoneNumber: this.signer.phone?.trim(),
        address: this.signer.address?.trim(),
        preferredLang: this.signer.preferredLang,
        ref: this.$route.params.ref
      }

      const clientId = this.$route.params.clientId
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${clientId}/signers/${this.email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      const signer = await response.text()
      this.$router.push({ path: `/${this.$route.params.clientId}/finished/${this.$route.params.ref}?self=true` })
    },
    formatDate (date: string | null) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    save (date: any) {
      (this.$refs.menu as VMenu).save(date)
    }
  }
})
