
import { PDFCanvasController, setupCanvas } from '@/lib/pdf-canvas'
import { FieldElement } from '@/lib/pdf-canvas/fields'
import { wait } from '@/utils'
import { debounce } from 'lodash'
import Vue from 'vue'
import Loading from './common/loading.vue'

interface ResizeHandler {
  (e: UIEvent): void
}

export default Vue.extend({
  name: 'PdfViewer',
  props: {
    pdfUrl: {
      type: String
    }
  },
  components: { Loading },
  data () {
    return {
      addSignatureDialog: false,
      insertImageDialog: false,
      controller: null as PDFCanvasController | null,
      resizeHandler: null as ResizeHandler | null,
      width: 0,
      height: 0,
      element: undefined,
      clicked: false,
      loading: false
    }
  },
  computed: {
    canvasSrc (): string {
      return this.pdfUrl.includes('?alt=media') ? this.pdfUrl : `${this.pdfUrl}?alt=media`
    }
  },
  mounted () {
    (async () => {
      this.resizeWrapper()
      await wait(200)
      if (this.canvasSrc) {
        this.loading = true
        this.controller = await setupCanvas('canvas', this.canvasSrc, 'pdf', [], this.$t('markups.sign').toString(), '')
        if (this.controller.canvas.height === 0 && this.resizeHandler) {
          this.resizeHandler(null as unknown as UIEvent)
        }
        this.$emit('ready')
        this.loading = false
      }
    })()

    this.resizeHandler = debounce(async () => {
      if (!this.controller) {
        throw new Error('`this.controller` is not initialized')
      }
      this.resizeWrapper()
      await wait(200)
      this.controller.resizeCanvas()
    }, 500)

    window.onresize = (e) => {
      if (this.resizeHandler) {
        this.resizeHandler(e)
      }
    }
  },
  methods: {
    scrollTo (element: FieldElement) {
      this.controller?.canvas.setActiveObject(element)
      this.controller?.scrollToActiveElement()
    },
    zoomIn () {
      this.controller?.zoomIn()
    },
    zoomOut () {
      this.controller?.zoomOut()
    },
    changePage (pageNum: string) {
      if (!this.controller) {
        return
      }

      if (pageNum === '') {
        return
      }

      let n = Number(pageNum)

      if (n > this.controller.totalPages) {
        n = this.controller.totalPages
      }

      if (n <= 0) {
        n = 1
      }

      this.controller.goToPage(n)
    },
    resizeWrapper () {
      const container = document.getElementById('pdf-container')
      this.width = container?.offsetWidth || 0
      this.height = (container?.offsetHeight || 0 /* 50 */)/*  - 50 */
    }
  }
})
