import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import Vue from 'vue'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

export default pinia
