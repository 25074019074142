import { PDFTextField } from 'pdf-lib'

export const renderTextField = (pdfField: PDFTextField, value: string, size?: number) => {
  const [widget] = pdfField.acroField.getWidgets()
  widget.getOrCreateBorderStyle().setWidth(-1)

  pdfField.setText(value)
  pdfField.enableReadOnly()

  if (size) pdfField.setFontSize(size)
}
