
import Vue, { PropOptions } from 'vue'
import xButton from './common/x-button.vue'
import { mdiCircle, mdiClose, mdiDrawPen, mdiPen, mdiEraser } from '@mdi/js'
export default Vue.extend({

  components: { xButton },

  props: {
    penSize: {
      type: Number,
      default: 1
    } as PropOptions<number>,
    penColor: {
      type: String,
      default: 'black'
    } as PropOptions<string>,
    tool: {
      type: String,
      default: 'pen'
    } as PropOptions<string>
  },

  data () {
    return {
      icons: {
        close: mdiClose,
        circle: mdiCircle,
        drawPen: mdiDrawPen,
        pen: mdiPen,
        eraser: mdiEraser
      }
    }
  }
})
