
import { PDFCanvasController, setupCanvas } from '@/lib/pdf-canvas'
import { FormField } from '@/lib/pdf-renderer'
import { Client } from '@/types/user'
import { downloadURL, getFileExtension, wait } from '@/utils'
import { getInitials } from '@/utils/name'
import { fabric } from 'fabric'
import { debounce } from 'lodash'
import Vue, { PropType } from 'vue'
import Loading from './common/loading.vue'
import SignatureDialog from './pdf-signature-dialog.vue'
import PdfToolbar from './pdf-toolbar.vue'
import { FieldElement } from '@/lib/pdf-canvas/fields'
import { FabricObject } from '@/types/fabric'

export interface Requester {
  name: string;
  title: string;
  pictureUrl?: string
  signatureUrl?: string
}

interface ResizeHandler {
  (e: UIEvent): void
}

export default Vue.extend({
  name: 'PdfSigner',
  components: { SignatureDialog, PdfToolbar, Loading },
  props: {
    pdfUrl: {
      type: String,
      required: false
    },
    imageUrl: {
      type: String,
      required: false
    },
    src: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: true
    },
    signerId: {
      type: String,
      required: true
    },
    requester: {
      type: Object as PropType<Requester>,
      required: true
    },
    reference: {
      type: String,
      required: true
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    formFields: {
      type: [],
      required: true
    }
  },
  data () {
    return {
      addSignatureDialog: false,
      insertImageDialog: false,
      controller: null as PDFCanvasController | null,
      resizeHandler: null as ResizeHandler | null,
      drawing: {
        tool: 'pen',
        pen: {
          size: 1,
          color: 'black'
        },
        enable: false
      },
      width: 0,
      height: 0,
      element: {} as fabric.Group,
      termsAndConditions: false,
      clicked: false,
      loading: false,
      childInterface: {
        update: (groupId?: string) => Object
      }
    }
  },
  computed: {
    canvasSrc (): string {
      return this.imageUrl || this.pdfUrl || this.src
    },

    srcType (): 'pdf'|'image' {
      if (this.pdfUrl) {
        return 'pdf'
      }

      if (this.imageUrl) {
        return 'image'
      }

      const fileExt = getFileExtension(this.canvasSrc)

      if (fileExt === 'pdf') {
        return 'pdf'
      } else if (['jpg', 'jpeg', 'png'].includes(fileExt)) {
        return 'image'
      } else {
        throw new Error('Invalid file type')
      }
    }
  },

  mounted () {
    (async () => {
      this.resizeWrapper()
      await wait(200)
      if (this.canvasSrc) {
        // this.controller = await setupCanvas('canvas', this.canvasSrc, this.srcType)
        this.controller = await setupCanvas('canvas', this.canvasSrc, 'pdf', this.formFields as FormField[], this.$t('markups.sign').toString(), this.requester.signatureUrl ?? '')

        this.controller.on('clicked', ({ event, groups, cue }: { event: fabric.IEvent<Event>, groups: FieldElement[], cue?: FieldElement }) => {
          const type = event.target?.name
          const element = (event.target as fabric.Group)
          const tt = element as never as FabricObject

          switch (type) {
            case 'radiobutton':
              groups.forEach(g => {
                const e = g as never as FabricObject
                g.item(1).visible = false
                g.dirty = true
                e.attrs = { ...e.attrs, elementId: undefined }
              })
              if (cue) {
                cue.visible = false
              }
              element.canvas?.renderAll()
              element.item(1).visible = true
              element.dirty = true
              element.canvas?.renderAll()
              tt.attrs = { type: 'radiobutton', elementId: (element as FieldElement).id }
              this.childInterface.update((element as FieldElement).groupId)
              break
            case 'checkbox':
              if (cue) {
                cue.visible = false
              }
              element.item(1).visible = !element.item(1).visible
              element.dirty = true
              element.canvas?.renderAll()
              tt.attrs = { type: 'checkbox', elementId: (element as FieldElement).id }
              this.childInterface.update((element as FieldElement).groupId)
              break
            default:
              this.element = element
              this.addSignatureDialog = true
          }
        })

        /* this.controller.on('hovered', (event: fabric.IEvent<Event>) => {
          const type = event.target?.name
          const element = (event.target as FieldElement)
          element.dirty = true

          switch (type) {
            case 'checkbox':
              element.item(0).visible = true
              break
            default:
              element.item(0).visible = true
              break
          }
          element.canvas?.renderAll()
        }) */

        /* this.controller.on('unhovered', (event: fabric.IEvent<Event>) => {
          const type = event.target?.name
          const element = (event.target as FieldElement)
          element.dirty = true

          switch (type) {
            case 'checkbox':
              element.item(0).visible = true
              break
            default:
              element.item(0).visible = true
              break
          }
          element.canvas?.renderAll()
        }) */

        this.$emit('ready')
      }
    })()

    this.resizeHandler = debounce(async () => {
      if (!this.controller) {
        throw new Error('`this.controller` is not initialized')
      }
      this.resizeWrapper()
      await wait(200)
      this.controller.resizeCanvas()
    }, 500)

    window.onresize = (e) => {
      if (this.resizeHandler) {
        this.resizeHandler(e)
      }
    }
  },
  methods: {
    getChildInterface (childInterface: any) {
      this.childInterface = childInterface
    },
    getInitials (name: string) {
      return getInitials(name)
    },
    scrollTo (element: FieldElement) {
      this.controller?.canvas.setActiveObject(element)
      this.controller?.scrollToActiveElement()
    },
    insertSignature (signature: fabric.Group) {
      if (!signature) return
      if (!this.controller) return

      this.controller.addSignature(signature, this.element)
      this.childInterface.update((this.element as FieldElement).groupId)
      this.element = {} as fabric.Group
    },
    zoomIn () {
      this.controller?.zoomIn()
    },
    zoomOut () {
      this.controller?.zoomOut()
    },
    async exportData () {
      if (this.srcType === 'pdf') {
        this.exportPDF()
      }
      if (this.srcType === 'image') {
        this.exportPNG()
      }
    },
    async exportPNG () {
      if (!this.controller) {
        throw new Error('`this.controller` is not initialized')
      }

      const imgBlob = await this.controller.exportPNG()
      const url = window.URL.createObjectURL(imgBlob)
      // openURL(url)
      downloadURL(url)
    },
    async exportPDF () {
      if (!this.controller) {
        throw new Error('`this.controller` is not initialized')
      }

      this.loading = true
      const pdfBytes = await this.controller.exportPDF()
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })

      const formData = new FormData()
      formData.append('file', pdfBlob, `${Date.now()}.pdf`)
      formData.append('id', this.signerId)
      formData.append('email', this.email)
      formData.append('reference', this.reference)
      formData.append('clientId', this.client?.id)

      fetch(`${process.env.VUE_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData
      })
        .then((response) => response.json())
        .then((x) => {
          this.loading = false
          this.$router.push({ path: `/${this.$route.params.clientId}/finished/${this.$route.params.id}` })
        })

      /* const url = window.URL.createObjectURL(pdfBlob)
      openURL(url) */
    },

    changePage (pageNum: string) {
      if (!this.controller) {
        return
      }

      if (pageNum === '') {
        return
      }

      let n = Number(pageNum)

      if (n > this.controller.totalPages) {
        n = this.controller.totalPages
      }

      if (n <= 0) {
        n = 1
      }

      this.controller.goToPage(n)
    },

    toggleDrawingMode () {
      if (!this.drawing.enable) {
        this.startDrawing()
      } else {
        this.stopDrawing()
      }
    },

    startDrawing () {
      this.drawing.enable = true
      this.controller?.setDrawingMode(true)
      this.updateDrawingPen({ size: 3, color: 'black' })
      this.updateDrawingTool('pen')
    },

    stopDrawing () {
      this.drawing.enable = false
      this.controller?.setDrawingMode(false)
    },

    updateDrawingPen (pen: { size: number, color: string}) {
      if (!this.controller) {
        throw new Error('controller is not initialized')
      }
      this.drawing.pen = pen
      this.controller.canvas.freeDrawingBrush.width = pen.size
      this.controller.canvas.freeDrawingBrush.color = pen.color
    },

    updateDrawingTool (tool: 'pen'|'eraser') {
      console.log('updateDrawingTool', { tool })
      this.drawing.tool = tool
      this.controller?.setDrawingTool(tool)
    },

    insertImage (evt: any) {
      if (!this.controller) {
        throw new Error('controller is not initialized')
      }
      this.controller.insertImage(evt.file, evt.opacity, evt.insertToAllPages)
    },

    onCanvasKeydown (e: KeyboardEvent) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if (!this.controller?.canvas) return

        const canvas = this.controller.canvas
        const activeObject = canvas?.getActiveObject()

        if (activeObject) {
          canvas.remove(activeObject)
        }
      }
    },

    resizeWrapper () {
      const container = document.getElementById('pdf-container')
      this.width = container?.offsetWidth || 0
      this.height = (container?.offsetHeight || 0 /* 50 */)/*  - 50 */
    },

    aggreementClicked () {
      console.log('HERE')
    }
  }

})
