
import { useAppStore } from '@/store/app'
import Vue from 'vue'
import XDialog from '../components/common/x-dialog.vue'

export default Vue.extend({
  name: 'Reset',
  components: {
    XDialog
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      input: {
        username: ''
      },
      loading: false,
      error: false,
      success: false,
      rules: {
        required: (value: string) => !!value || this.$t('formValidation.required'),
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('formValidation.invalidEmail')
        }
      }
    }
  },
  methods: {
    async login () {
      if (this.input.username !== '') {
        this.loading = true
        const formData = {
          email: this.input.username?.toLowerCase()
        }

        const response = await fetch(`${process.env.VUE_APP_API_URL}/users/reset`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })

        if (!response.ok) {
          this.loading = false
          this.error = true
          return
        }

        this.loading = false
        this.success = true
        this.input.username = ''
      } else {
        console.log('An email must be present')
      }
    },
    back () {
      this.$router.push('/')
    }
  }
})
