
import Vue from 'vue'
import { VForm, VMenu } from '../types/form'
import Loading from '../components/common/loading.vue'
import { useAppStore } from '@/store/app'

export default Vue.extend({
  name: 'NewSigner',
  components: { Loading },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      signer: {
        id: '',
        email: '',
        name: '',
        lastname: '',
        historyNumber: '',
        birthday: '',
        gender: '',
        phone: '',
        address: '',
        preferredLang: ''
      },
      onboardingType: 0,
      showSuccess: false,
      showFailure: false,
      showGeneralFailure: false,
      date: null,
      activePicker: null,
      menu: false,
      rules: {
        required: (value: string) => !!value || this.$t('formValidation.required'),
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('formValidation.invalidEmail')
        },
        date: (value: string) => {
          const t = new Date(value)
          // @ts-expect-error: blah
          return (t instanceof Date && isFinite(t)) || this.$t('formValidation.invalidDate')
        }
      },
      loading: false,
      height: 0,
      width: 0,
      auto: false
    }
  },
  beforeMount () {
    this.getSignerId()
  },
  mounted () {
    const height = (this.$refs.container as HTMLElement)?.clientHeight + 70
    const width = (this.$refs.container as HTMLElement)?.clientWidth
    this.height = height
    this.width = width
  },
  watch: {
    date (val) {
      this.signer.birthday = this.formatDate(this.date) ?? ''
    }
  },
  methods: {
    async getSignerId () {
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/settings/autoSignerId`)
      const data = await res.json()

      this.auto = data.auto
      this.signer.historyNumber = data.auto ? data.new : ''
    },
    async addSigner () {
      if (!await (this.$refs.manualForm as VForm).validate()) {
        return
      }

      this.loading = true

      const formData = {
        id: this.signer.id?.trim(),
        name: this.signer.name?.trim(),
        lastname: this.signer.lastname?.trim(),
        email: this.signer.email?.trim(),
        historyNumber: this.signer.historyNumber?.trim(),
        birthday: this.signer.birthday,
        gender: this.signer.gender,
        phone: this.signer.phone?.trim(),
        address: this.signer.address?.trim()
      }

      const user = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${user}/signers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      const signer = await response.text()

      this.$emit('added', formData)
    },
    async requestData () {
      if (!await (this.$refs.autoForm as VForm).validate()) {
        return
      }

      this.loading = true

      const formData = {
        historyNumber: this.signer.historyNumber?.trim()
      }

      const user = this.app.selectedClient?.id ?? ''
      const { status } = await fetch(`${process.env.VUE_APP_API_URL}/${user}/signers/${this.signer.email?.trim()}/registry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      this.loading = false

      if (status === 200) {
        this.getSignerId()
        this.signer.email = ''
        this.showSuccess = true
      } else if (status === 409) {
        this.getSignerId()
        this.showFailure = true
      } else {
        this.getSignerId()
        this.showGeneralFailure = true
      }

      setInterval(() => {
        this.showSuccess = false
        this.showFailure = false
        this.showGeneralFailure = false
      }, 3000)
    },
    formatDate (date: string | null) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    save (date: any) {
      (this.$refs.menu as VMenu).save(date)
    }
  }
})
