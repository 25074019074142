
import Vue from 'vue'
import { fabric } from 'fabric'

export default Vue.extend({
  props: {
    value: {
      type: Boolean
    },
    element: {
      type: Object
    }
  },

  data () {
    return {
      canvas: null as fabric.Canvas | null
    }
  },

  watch: {
    value (v) {
      if (v && !this.canvas) {
        this.setupSignatureCanvas()
      }
    }
  },

  methods: {
    async setupSignatureCanvas () {
      this.canvas = new fabric.Canvas('signature-canvas', {
        isDrawingMode: true
      })
      this.canvas.freeDrawingBrush.color = 'blue'
      this.canvas.freeDrawingBrush.width = 3
    },

    clear () {
      if (!this.canvas) {
        return
      }
      for (const obj of this.canvas?.getObjects()) {
        this.canvas.remove(obj)
      }

      this.canvas.requestRenderAll()
    },
    cancel () {
      this.clear()
      this.$emit('input', false)
    },
    submit () {
      if (!this.canvas) {
        return
      }
      const paths = this.canvas.getObjects()
      if (paths.length === 0) {
        return
      }

      const options = this.element
        ? {
            top: this.element?.top,
            left: this.element?.left,
            height: this.element?.height,
            originX: 'left',
            originY: 'top'
          }
        : {}

      const signature = new fabric.Group(paths, options)
      this.$emit('submit', signature)
      this.$emit('input', false)
    }
  }
})
