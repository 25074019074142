
import Loading from '@/components/common/loading.vue'
import SaveChanges from '@/components/common/save-changes.vue'
import { useAppStore } from '@/store/app'
import { getInitials } from '@/utils/name'
import Vue from 'vue'
import SignaturePad from '../components/signature-pad.vue'
import { dataURItoBlob } from '@/utils/image'

export default Vue.extend({
  name: 'Profile',
  components: { SignaturePad, SaveChanges, Loading },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      pictureUrl: '',
      displayName: '',
      email: '',
      initialCity: '',
      city: '',
      initialTitle: '',
      title: '',
      signatureUrl: '',
      canvas: null,
      showSignaturePad: false,
      loading: false,
      signaturePadInterface: {
        submit: () => Object
      }
    }
  },
  beforeMount () {
    this.displayName = this.app.customer?.name ?? ''
    this.email = this.app.customer?.email ?? ''
    this.fetch()
  },
  methods: {
    getInitials (name: string) {
      return getInitials(name)
    },
    async fetch () {
      const user = this.app.selectedClient?.id ?? ''
      const customer = this.app.customer?.email ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/requesters/${customer}`)
      const data = await res.json()

      this.initialCity = data.city
      this.city = data.city
      this.initialTitle = data.title
      this.title = data.title
      this.pictureUrl = data.pictureUrl
      this.signatureUrl = data.signatureUrl
    },
    getSignaturePadInterface  (signaturePadInterface: any) {
      this.signaturePadInterface = signaturePadInterface
    },
    startSignature () {
      this.canvas = null
      this.showSignaturePad = true
    },
    cancelSignature () {
      this.showSignaturePad = false
    },
    acceptSignature () {
      this.signaturePadInterface.submit()
      this.showSignaturePad = false
    },
    submitSignature () {
      this.showSignaturePad = false
    },
    cancel () {
      this.canvas = null
      this.city = this.initialCity
      this.title = this.initialTitle
    },
    async save () {
      this.loading = true
      const user = this.app.selectedClient?.id ?? ''

      if (this.canvas) {
        const signatureBlob = dataURItoBlob(this.canvas as unknown as string)

        const formData = new FormData()
        formData.append('file', signatureBlob)
        formData.append('email', this.email)

        fetch(`${process.env.VUE_APP_API_URL}/${user}/upload/signature`, {
          method: 'POST',
          body: formData
        })
          .then((response) => response.json())
          .catch((_) => {
            // Ignore error
          })
      }

      if (this.initialCity !== this.city || this.initialTitle !== this.title) {
        const formData = {
          email: this.email,
          title: this.title,
          city: this.city
        }

        const response = await fetch(`${process.env.VUE_APP_API_URL}/${user}/requesters`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })

        this.initialCity = this.city
        this.initialTitle = this.title
      }

      this.fetch().then((_) => {
        this.canvas = null
      })

      this.loading = false
    }
  }
})
