
import Vue from 'vue'
import FormSchema from '@formschema/native'
import { FormField } from '@/lib/pdf-renderer'

export default Vue.extend({
  name: 'MissingInformationForm',
  components: { FormSchema },
  props: {
    formFields: {
      type: [],
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    signer: {
      type: String,
      required: true
    }
  },
  data: () => ({
    schema: {},
    model: {}
  }),
  mounted () {
    const properties: {[k: string]: any} = {};
    ((this.formFields ?? []) as FormField[]).forEach((field) => {
      properties[field.key] = {
        type: 'string'
      }
    })

    this.schema = {
      type: 'object',
      properties,
      required: ((this.formFields ?? []) as FormField[]).map((field) => field.key)
    }
  },
  computed: {
    descriptor () {
      const properties: {[k: string]: any} = {};
      ((this.formFields ?? []) as FormField[]).forEach((field) => {
        properties[field.key] = {
          label: this.$t(`missing.${field.key}`)
        }
      })
      return { properties }
    }
  },
  methods: {
    async submit (e: any) {
      this.$emit('loading')

      const properties: {[k: string]: any} = {};
      ((this.formFields ?? []) as FormField[]).forEach((field) => {
        properties[field.key] = e.field.value[field.key]
      })

      const body = {
        id: this.signer,
        properties
      }

      console.log(body)

      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/${this.clientId}/signers`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      )
      const action = await res
      console.log(action)

      const fields = ((this.formFields ?? []) as FormField[]).map((field) => ({
        description: field.description,
        index: field.index,
        key: field.key,
        source: 'signer',
        type: field.type,
        value: e.field.value[field.key]
      }))
      this.$emit('submit', fields)
    }
  }
})

