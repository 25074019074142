
import Vue from 'vue'
// import xIcon from './common/x-icon.vue'
import {
  mdiDownload,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiSignatureFreehand,
  mdiFormatListBulletedSquare,
  mdiDrawPen,
  mdiImagePlus
} from '@mdi/js'
import { isMobile } from '@/utils/device'
import PdfDrawingToolbar from './pdf-drawing-toolbar.vue'
import xButton from './common/x-button.vue'
import { FieldElement } from '@/lib/pdf-canvas/fields'

const groupByKey = (list: any[], key: string) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

const getCenterElement = (group: FieldElement[]) => {
  if (group.length === 1) {
    return group[0]
  }

  const cue = group.filter((g) => g.name === 'cue')

  return cue[0]
}

export default Vue.extend({
  components: {
    // xIcon,
    PdfDrawingToolbar,
    xButton
  },
  props: {
    page: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    isDrawing: {
      type: Boolean,
      default: false
    },
    drawingPen: {
      type: Object,
      required: true
    },
    drawingTool: {
      type: String,
      required: true
    },
    isFinished: {
      type: Boolean
    },
    elements: {
      type: []
    }
  },
  computed: {
    isMobile (): boolean {
      return isMobile()
    }
  },
  mounted () {
    const elements = (this.elements as [])?.filter((element: FieldElement) => element.id)
    this.requiredActions = groupByKey(elements, 'groupId')
    this.requiredActionsGroups = Object.keys(this.requiredActions)
    this.emitInterface()
  },
  data () {
    return {
      icons: {
        zoomIn: mdiMagnifyPlus,
        zoomOut: mdiMagnifyMinus,
        sign: mdiSignatureFreehand,
        export: mdiDownload,
        pages: mdiFormatListBulletedSquare,
        drawPen: mdiDrawPen,
        imagePlus: mdiImagePlus
      },
      requiredActions: {} as Record<string, FieldElement[]>,
      requiredActionsGroups: new Array<string>(),
      started: false,
      index: 0,
      nextDisabled: false
    }
  },
  methods: {
    nextElement () {
      if (!this.started) {
        this.started = true
        this.$emit('scrollTo', getCenterElement(this.requiredActions[this.requiredActionsGroups[0]]))
        return
      }

      this.index = this.index + 1
      this.$emit('scrollTo', getCenterElement(this.requiredActions[this.requiredActionsGroups[this.index]]))

      if (this.index === this.requiredActionsGroups.length - 1) {
        this.nextDisabled = true
      }
    },
    updateIndex (groupId?: string) {
      if (groupId) {
        this.started = true
        this.index = this.requiredActionsGroups.findIndex((g) => g === groupId)
        if (this.index === this.requiredActionsGroups.length - 1) {
          this.nextDisabled = true
        }
      }
    },
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface () {
      this.$emit('interface', {
        update: (groupId?: string) => this.updateIndex(groupId)
      })
    }
  }
})
