
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean
    },
    maxHeight: {
      type: String,
      default: '360px'
    }
  }
})
