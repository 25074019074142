
import { useAppStore } from '@/store/app'
import Vue from 'vue'

export default Vue.extend({
  name: 'SaveChanges',
  props: {
    open: { type: Boolean, required: true }
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      visible: false,
      color: ''
    }
  },
  beforeMount () {
    this.color = this.app.primaryColor
  },
  methods: {
    close () {
      this.visible = false
      this.$emit('close')
    },
    save () {
      this.visible = false
      this.$emit('save')
    }
  },
  watch: {
    open () {
      this.visible = this.open
    },
    'app.primaryColor' (newValue, oldValue) {
      this.color = this.app.primaryColor
    }
  }
})
