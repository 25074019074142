export const MAX_WIDTH = 150
export const MAX_HEIGHT = 100

export interface Field {
  width: number;
  height: number;
  left: number;
  top: number;
  type: 'signature' | 'radio';
}

export interface FieldElement extends fabric.Group {
  id: string;
  groupId?: string;
}
