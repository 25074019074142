import { PDFCheckBox } from 'pdf-lib'

export const renderCheckboxField = (pdfCheckbox: PDFCheckBox, value?: string) => {
  const [widget] = pdfCheckbox.acroField.getWidgets()
  // widget.getOrCreateBorderStyle().setWidth(-1)

  if (value) {
    pdfCheckbox.check()
  }

  pdfCheckbox.enableReadOnly()
}
