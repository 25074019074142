import { fabric } from 'fabric'
import { Field, FieldElement, MAX_HEIGHT, MAX_WIDTH } from '.'

export const createRadioGroupField = (
  fieldGroup: Field[],
  index: number,
  onClick: (event: fabric.IEvent<Event>, groups: FieldElement[], cue?: FieldElement) => void,
  onHovered: (event: fabric.IEvent<Event>) => void,
  onUnhovered: (event: fabric.IEvent<Event>) => void
) => {
  let top = fieldGroup?.[0]?.top
  let bottom = 0

  const groups = fieldGroup.map((field) => {
    top = Math.min(top, field.top)
    bottom = Math.max(bottom, field.top + field.height)
    const width = Math.min(field.width, MAX_WIDTH)
    const height = Math.min(field.height, MAX_HEIGHT)

    const shadow = new fabric.Shadow({
      color: 'gray',
      blur: 20
    })

    const biggerRadius = width % 2 === 0 ? Math.floor(width / 2) : Math.floor((width - 3) / 2)

    const circle = new fabric.Circle({
      radius: biggerRadius,
      fill: 'rgba(0,0,0,0.05)',
      stroke: 'red',
      strokeWidth: 0,
      originX: 'center',
      originY: 'center',
      shadow,
      visible: false
    })

    const innerCircle = new fabric.Circle({
      radius: biggerRadius - 6,
      fill: '#747678',
      strokeWidth: 0,
      originX: 'center',
      originY: 'center',
      visible: false
    })

    const group = new fabric.Group([circle, innerCircle], {
      name: 'radiobutton',
      left: field.left,
      top: 2 * field.top + 10,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      lockUniScaling: true,
      hasRotatingPoint: false,
      hasControls: false
    }) as FieldElement

    return group
  })

  const text = new fabric.Text('CHOOSE', {
    originX: 'center',
    originY: 'center',
    fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
    left: 60,
    top: 35,
    fontSize: 20
  })

  const rect = new fabric.Polygon([
    { x: 0, y: 0 },
    { x: 120, y: 0 },
    { x: 150, y: 35 },
    { x: 120, y: 70 },
    { x: 0, y: 70 }
  ],
  {
    fill: '#FFc423'
  })

  const mark = new fabric.Group([rect, text], {
    name: 'cue',
    left: -100,
    top: 2 * top + ((bottom - top) / 2),
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingFlip: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
    hasRotatingPoint: false,
    hasControls: false,
    visible: bottom - top > 100,
    hasBorders: false
  }) as FieldElement

  mark.id = `cue-${Date.now()}`
  mark.groupId = `group-${index}`

  groups.forEach((group: FieldElement) => {
    group.id = `radiobutton-${Date.now()}`
    group.groupId = `group-${index}`
    group.on('mousedown', (event: fabric.IEvent<Event>) => {
      onClick(event, groups, groups.length > 1 ? mark : undefined)
    })
    group.on('mouseover', (event: fabric.IEvent<Event>) => {
      onHovered(event)
    })
    group.on('mouseout', (event: fabric.IEvent<Event>) => {
      onUnhovered(event)
    })
  })

  return groups.length > 1 ? [...groups, mark] : groups
}
