import pinia from '@/plugins/pinia'
import { useAppStore } from '@/store/app'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Clients from '../views/Clients.vue'
import Documents from '../views/Documents.vue'
import Expired from '../views/Expired.vue'
import Finished from '../views/Finished.vue'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Profile from '../views/Profile.vue'
import Reset from '../views/Reset.vue'
import Root from '../views/Root.vue'
import SelfRegistry from '../views/SelfRegistry.vue'
import Settings from '../views/Settings.vue'
import Sign from '../views/Sign.vue'
import Signers from '../views/Signers.vue'
import Unauthorized from '../views/Unauthorized.vue'

Vue.use(VueRouter)

function checkAdminRights (to: any, from: any, next :any) {
  const app = useAppStore(pinia)

  if (app.isAdmin) {
    next()
  } else {
    next({ path: `/${app.selectedClient?.id}/home` })
  }
}

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    meta: { guest: true }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: { guest: true }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:clientId/home',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:clientId/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:clientId/signers',
    name: 'Signers',
    component: Signers,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:clientId/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:clientId/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      authRequired: true
    },
    beforeEnter: checkAdminRights
  },
  {
    path: '/:clientId/signers/:ref/registry',
    name: 'SelfSignerRegistry',
    component: SelfRegistry
  },
  {
    path: '/:clientId/sign/:id',
    name: 'Sign',
    component: Sign
  },
  {
    path: '/:clientId/uh-oh',
    name: '401',
    component: Unauthorized
  },
  {
    path: '*/expired/*',
    name: 'Expired',
    component: Expired
  },
  {
    path: '*/finished/*',
    name: 'Finished',
    component: Finished
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    const app = useAppStore(pinia)

    const token = localStorage.getItem('token')
    const user = app.selectedClient?.id

    if (token && token !== '' && (user && user !== '')) {
      next()
    } else {
      const loginpath = `${window.location.pathname}${window.location.search}`
      next({ path: '/', query: { from: loginpath } })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    next()
  } else {
    next()
  }
})

export default router
