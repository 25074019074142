
import { useAppStore } from '@/store/app'
import Vue, { PropOptions } from 'vue'
import DocumentsPreviewItem from './documents-preview-item.vue'

export default Vue.extend({
  name: 'DocumentsPreview',
  components: { DocumentsPreviewItem },
  props: {
    signer: {
      type: Object,
      required: true
    } as PropOptions<{ id: string; }>
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      total: 0,
      documents: new Array<{status: string; title: string; timestamp: number; id: string; url?: string}>()
    }
  },
  mounted () {
    this.getDocuments()
    this.emitInterface()
  },
  watch: {
    async signer () {
      this.getDocuments()
    },
    documents () {
      this.total = this.documents.length
    }
  },
  methods: {
    async getDocuments () {
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/signers/${this.signer.id}/documents`)
      const documents = await res.json()

      this.documents = (documents ?? [])
        .map((document: {signed?: number; revoked?: number; createdAt: number;}) => ({
          ...document,
          status: document.signed
            ? 'signed'
            : document.revoked
              ? 'revoked'
              : 'waiting',
          timestamp: document.signed ?? document.revoked ?? document.createdAt ?? Date.now()
        }))
        .sort((r1: {timestamp: number}, r2: {timestamp: number}) => (r1.timestamp < r2.timestamp) ? 1 : (r1.timestamp > r2.timestamp) ? -1 : 0)
    },
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface () {
      this.$emit('interface', {
        refetch: async () => this.getDocuments()
      })
    },
    viewDocument (document: { name: string; url: string}) {
      this.$emit('viewDocument', document)
    }
  }
})
