
import { useAppStore } from '@/store/app'
import { Client } from '@/types/user'
import { reset } from '@/utils/storage'
import Vue from 'vue'

export default Vue.extend({
  name: 'Navbar',
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      logoUrl: '',
      displayName: '',
      username: '',
      email: '',
      pictureUrl: '',
      initials: '',
      showActions: false,
      selectedClient: '',
      clients: new Array<Client>(),
      isAdmin: false,
      isBasic: false
    }
  },
  beforeMount () {
    const displayName = this.app.customer?.name ?? ''
    this.displayName = displayName
    this.pictureUrl = this.app.selectedClient?.pictureUrl ?? ''
    this.initials = this.app.initials
    this.email = this.app.customer?.email ?? ''
    this.logoUrl = this.app.selectedClient?.logo ?? ''
    this.username = this.app.selectedClient?.name ?? ''
    this.clients = this.app.clients
    this.selectedClient = this.app.selectedClient?.id ?? ''
    this.isAdmin = this.app.isAdmin
    this.isBasic = this.app.isBasic
  },
  watch: {
    $route (to, from) {
      const displayName = this.app.customer?.name ?? ''
      this.displayName = displayName
      this.pictureUrl = this.app.selectedClient?.pictureUrl ?? ''
      this.initials = this.app.initials
      this.email = this.app.customer?.email ?? ''
      this.logoUrl = this.app.selectedClient?.logo ?? ''
      this.username = this.app.selectedClient?.name ?? ''
      this.clients = this.app.clients
      this.selectedClient = this.app.selectedClient?.id ?? ''
      this.isAdmin = this.app.isAdmin
      this.isBasic = this.app.isBasic
    },
    selectedUser () {
      const clientId = this.$route.params.clientId
      if (clientId && clientId !== this.selectedClient) {
        this.close()
        const client = this.clients.find(user => user.id === this.selectedClient) as Client
        this.app.selectClient({ name: client.name, id: client.id, logo: client.logo, color: client.color, roles: client.roles })
        this.$router.push(`/${client?.id}/home`)
      }
    },
    'app.selectedClient' (newValue, oldValue) {
      if (newValue) {
        this.logoUrl = `${newValue?.logo}&rnd=${Date.now()}`
        this.pictureUrl = newValue?.pictureUrl ? `${newValue?.pictureUrl}&rnd=${Date.now()}` : ''
      }
    }
  },
  methods: {
    toggleActions () {
      this.showActions = !this.showActions
    },
    close () {
      this.showActions = false
    },
    logout () {
      reset()
      this.app.$reset()
      this.showActions = false
      this.logoUrl = ''

      this.$router.push('/')
    }
  }
})
