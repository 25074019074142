export const set = (user: any) => {
  localStorage.setItem('token', JSON.stringify(user.credentials?.stsTokenManager))
}

export const accessToken = () => {
  try {
    const rawToken = localStorage.getItem('token')
    const token = rawToken ? JSON.parse(rawToken) : undefined

    return token?.accessToken
  } catch (e) {
    reset()
    return undefined
  }
}

export const reset = () => {
  localStorage.removeItem('token')
}

export const optedForCookiesBefore = () => {
  const optedIn = localStorage.getItem('optInCookies')

  return optedIn !== null
}

export const optCookies = (optIn: boolean) => {
  localStorage.setItem('optInCookies', `${optIn}`)
}

export const getOptCookies = () => {
  const optedIn = localStorage.getItem('optInCookies')

  return optedIn === 'true'
}
