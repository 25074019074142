
import { useAppStore } from '@/store/app'
import { Client } from '@/types/user'
import Vue from 'vue'

export default Vue.extend({
  name: 'Clients',
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      clients: new Array<Client>(),
      initials: '',
      displayName: ''
    }
  },
  beforeMount () {
    this.displayName = this.app.customer?.name ?? ''
    this.initials = this.app.initials
    this.clients = this.app.clients
  },
  methods: {
    redirect (client: Client) {
      this.app.selectClient({ name: client.name, id: client.id, logo: client.logo, color: client.color, roles: client.roles })
      this.$router.push({ path: `/${client.id}/home` })
    }
  }
})
