
import { useAppStore } from '@/store/app'
import { set } from '@/utils/storage'
import Vue from 'vue'
import XDialog from '../components/common/x-dialog.vue'
import CookieBanner from '../components/cookie-banner.vue'

export default Vue.extend({
  name: 'Root',
  components: {
    XDialog,
    CookieBanner
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      input: {
        username: '',
        password: '',
        revealPassword: false
      },
      loading: false,
      unauthorized: false,
      forbidden: false,
      invalid: false
    }
  },
  methods: {
    async login () {
      if (this.input.username !== '' && this.input.password !== '') {
        this.loading = true
        const formData = {
          email: this.input.username?.toLowerCase(),
          password: this.input.password
        }

        const response = await fetch(`${process.env.VUE_APP_API_URL}/users/signin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })

        if (!response.ok) {
          this.loading = false
          this.forbidden = response.status === 403
          this.unauthorized = response.status === 401
          this.invalid = response.status === 424
          return
        }

        const user = await response.json()

        set(user)
        this.app.setCustomer({ name: user.credentials?.displayName, email: user.credentials?.email })
        this.loading = false
        if (user.clients?.length > 1) {
          this.app.setClients(user.clients)
          this.$router.push({ name: 'Clients' })
        } else {
          this.app.setClients(user.clients)
          this.app.selectClient({ id: user.clients?.[0].id, name: user.clients?.[0].name, logo: user.clients?.[0].logo, color: user.clients?.[0].color, roles: user.clients?.[0].roles, pictureUrl: user.clients?.[0].pictureUrl })
          if (this.$route.query.from && this.$route.query.from.includes(`/${user.clients?.[0].id}`)) {
            this.$router.replace(this.$route.query.from as string)
          } else {
            this.$router.push(`/${user.clients?.[0].id}/home`)
          }
        }
      } else {
        console.log('A username and password must be present')
      }
    },
    reset () {
      this.$router.push('Reset')
    }
  }
})
