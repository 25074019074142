
import Vue from 'vue'
import { LOCALES, Locales } from '@/i18n/locales'
import { mdiWeb } from '@mdi/js'
import xIcon from './common/x-icon.vue'

export default Vue.extend({
  name: 'Locale',
  components: {
    xIcon
  },
  data () {
    return {
      langs: LOCALES,
      checked: false,
      icons: {
        mdiWeb
      }
    }
  },
  methods: {
    toggleCheck (event: Event) {
      this.checked = (event.target as HTMLInputElement)?.checked
    },
    selectLang ({ value }: { value: Locales }) {
      this.checked = false
      this.$i18n.locale = value
    },
    findLanguage (value: string) {
      return this.langs.find(lang => lang.value === value)?.caption
    }
  }
})
