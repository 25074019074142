import { Customer } from '@/types/customer'
import { Client } from '@/types/user'
import { parse, stringify } from '@/utils'
import { getInitials } from '@/utils/name'
import { isAdmin, isBasic } from '@/utils/roles'
import { defineStore } from 'pinia'
import { primary } from '@/constants'

interface AppStore {
  customer?: Customer;
  clients: Client[];
  selectedClient?: Client;
}

export const useAppStore = defineStore('app', {
  state: (): AppStore => ({
    customer: undefined,
    clients: [],
    selectedClient: undefined
  }),
  getters: {
    initials: (state) => getInitials(state.customer?.name),
    isAdmin: (state) => isAdmin(state.selectedClient?.roles),
    isBasic: (state) => isBasic(state.selectedClient?.roles),
    primaryColor: (state) => (state.selectedClient?.color ?? primary).toUpperCase()
  },
  actions: {
    setCustomer (customer: Customer) {
      this.customer = customer
    },
    setClients (clients: Client[]) {
      this.clients = clients
    },
    selectClient (client: Client) {
      this.selectedClient = client
    },
    setPrimaryColor (color: string) {
      this.selectedClient = {
        id: this.selectedClient?.id ?? '',
        logo: this.selectedClient?.logo ?? '',
        ...this.selectedClient,
        color
      }
    },
    refreshLogo () {
      this.selectedClient = {
        id: this.selectedClient?.id ?? '',
        logo: this.selectedClient?.logo ?? '',
        ...this.selectedClient
      }
    }
  },
  persist: {
    key: 'session',
    storage: sessionStorage,
    serializer: {
      deserialize: parse,
      serialize: stringify
    }
  }
})
