import { useAppStore } from '@/store/app'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pinia from './pinia'

Vue.use(Vuetify)

const app = useAppStore(pinia)
const color = app.primaryColor

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: color,
        accent: '#ffc423'
      }
    }
  }
})
