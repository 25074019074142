
import Vue from 'vue'

export default Vue.extend({
  name: 'Loading',
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    },
    opacity: {
      type: Number
    }
  }
})
