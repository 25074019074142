
import { useAppStore } from '@/store/app'
import Vue, { PropOptions } from 'vue'
import { VForm } from '../../types/form'
import Loading from '../common/loading.vue'
import RequesterLookup from '../requester-lookup.vue'
import TemplateLookup from '../template-lookup.vue'
import DocumentsPreview from './documents-preview.vue'

export default Vue.extend({
  name: 'RequestSignature',
  components: { DocumentsPreview, Loading, RequesterLookup, TemplateLookup },
  props: {
    signer: {
      type: Object,
      required: true
    } as PropOptions<{ id: string; name: string; lastname: string; }>
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      loading: false,
      height: 0,
      width: 0,
      childInterface: {
        refetch: async () => Object
      },
      selectedRequester: '',
      requesterLookupInterface: {
        clear: () => Object,
        refetch: async () => Object
      },
      templatesLookupInterface: {
        clear: () => Object,
        refetch: async () => Object
      },
      selectedTemplates: new Array<any>(),
      rules: {
        required: (value: string | Array<any>) => (
          Array.isArray(value)
            ? value.length > 0 || this.$t('formValidation.required')
            : !!value || this.$t('formValidation.required')
        )
      }
    }
  },
  watch: {
    signer () {
      this.requesterLookupInterface.clear()
      this.templatesLookupInterface.clear()
    }
  },
  mounted () {
    const height = (this.$refs.container as HTMLElement)?.clientHeight + 70
    const width = (this.$refs.container as HTMLElement)?.clientWidth
    this.height = height
    this.width = width
  },
  methods: {
    // Setting the interface when emitted from child
    getChildInterface (childInterface: any) {
      this.childInterface = childInterface
    },
    getRequesterLookupInterface (childInterface: any) {
      this.requesterLookupInterface = childInterface
    },
    getTemplatesLookupInterface (childInterface: any) {
      this.templatesLookupInterface = childInterface
    },
    requesterChanged (requester: string | null) {
      this.selectedRequester = requester ?? ''
    },
    templatesChanged (templates: any[]) {
      this.selectedTemplates = templates ?? []
    },
    async requestSignature () {
      if (!await (this.$refs.container as VForm).validate()) {
        return
      }

      this.loading = true

      const formData = {
        signer: this.signer.id,
        requester: this.selectedRequester,
        /* templates: this.selectedTemplates?.map((template) => ({
          id: template.id
        })) */
        templates: [{
          id: this.selectedTemplates
        }]
      }

      const user = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${user}/actions`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      this.loading = false
      this.childInterface.refetch()

      this.$emit('requested')
    },
    pdfReady () {
      this.loading = false
    }
  }
})
