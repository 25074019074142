
import Vue from 'vue'
import Footer from './components/footer.vue'
import navbar from './components/navbar.vue'
import { useAppStore } from './store/app'

export default Vue.extend({
  name: 'App',
  components: {
    navbar,
    Footer
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      color: ''
    }
  },
  beforeMount () {
    this.color = this.app.primaryColor
  },
  watch: {
    $route (to, from) {
      this.color = this.app.primaryColor
      this.$vuetify.theme.currentTheme.primary = this.app.primaryColor
    },
    'app.selectedClient' (newValue, oldValue) {
      this.color = this.app.primaryColor
      this.$vuetify.theme.currentTheme.primary = this.app.primaryColor
    },
    'app.primaryColor' (newValue, oldValue) {
      this.color = this.app.primaryColor
    }
  }
})
