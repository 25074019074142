
import { useAppStore } from '@/store/app'
import Vue from 'vue'

export default Vue.extend({
  name: 'SignerLookup',
  props: {
    signerId: {
      type: String,
      default: ''
    }
  },
  watch: {
    async signerId () {
      this.selectedSigner = this.signers.find((signer) => signer.id === this.signerId)
    }
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      signers: new Array<any>(),
      selectedSigner: {}
    }
  },
  async mounted () {
    this.fetch()
    this.emitInterface()
  },
  methods: {
    filterData (item: any, queryText: string) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.id.toLowerCase().includes(queryText.toLowerCase()) ||
        item.historyNumber.toLowerCase().includes(queryText.toLowerCase())
      )
    },
    changeValue (value: { name: string; id: string; lastname: string } | null) {
      this.$emit('changed', value ? { name: value.name, id: value.id, lastname: value.lastname } : value)
    },
    clear () {
      this.selectedSigner = {}
    },
    async fetch () {
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/signers`)
      const data = await res.json()
      const signers = Object.keys(data).map(id => data[id]).filter((signer) => !!signer.id)

      this.signers = signers
    },
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface () {
      this.$emit('interface', {
        clear: () => this.clear(),
        refetch: async () => this.fetch()
      })
    }
  }
})
