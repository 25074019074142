
import { FormField } from '@/lib/pdf-renderer'
import Vue from 'vue'
import XDialog from '../components/common/x-dialog.vue'
import PdfSigner, { Requester } from '../components/pdf-signer.vue'
import MissingInformationForm from '../components/missing-information-form.vue'
import CookieBanner from '../components/cookie-banner.vue'
import { Client } from '@/types/user'
import { useAppStore } from '@/store/app'

export default Vue.extend({
  name: 'Sign',
  components: {
    XDialog,
    PdfSigner,
    MissingInformationForm,
    CookieBanner
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      color: '',
      src: '',
      loading: true,
      width: '',
      height: '',
      email: '',
      signer: '',
      requester: {} as Requester,
      reference: '',
      client: {} as Client,
      formFields: new Array<FormField>(),
      missingFields: new Array<FormField>()
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const id = this.$route.params.id
        const clientId = this.$route.params.clientId
        const res = await fetch(`${process.env.VUE_APP_API_URL}/${clientId}/actions/${id}`)
        const action = await res.json()
        console.log(action)
        this.email = action.signer.email
        this.signer = action.signer.id
        this.requester = {
          name: action.requester.name,
          title: action.requester.title,
          pictureUrl: action.requester.pictureUrl,
          signatureUrl: action.requester.signatureUrl
        }
        this.reference = id
        this.client = {
          id: clientId,
          logo: action.client.logo
        }
        this.color = action.client.color
        this.src = action.templates[0].url
        this.formFields = action.templates[0].formFields
        this.missingFields = action.templates[0].missingFields ?? []
        if (this.missingFields.length > 0) {
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.$router.push({ path: `/${this.$route.params.clientId}/expired/${this.$route.params.id}` })
      }
    },
    onDataCompleted (fields: FormField[]) {
      fields.forEach((field) => {
        const currentField = this.formFields.find((formField) => formField.index === field.index) as FormField
        currentField.value = field.value
      })
      this.loading = true
      this.missingFields = []
    }
  },
  mounted () {
    this.color = this.app.primaryColor
    window.addEventListener('resize', () => {
      this.width = (window.innerWidth - 16) + 'px'
      this.height = (window.innerHeight - 16) + 'px'
    })
  },
  computed: {
    window () {
      return window
    }
  },
  watch: {
    $route (to, from) {
      this.color = this.app.primaryColor
      this.$vuetify.theme.currentTheme.primary = this.app.primaryColor
    },
    'app.primaryColor' (newValue, oldValue) {
      this.color = this.app.primaryColor
    }
  }
})
