import { FabricObject } from '@/types/fabric'
import { CoordsTranformer, PDFDimensionTransformer } from '@/utils'
import { fabric } from 'fabric'
import _ from 'lodash'
import { PDFCheckBox, PDFDocument, PDFField, PDFForm, PDFImage, PDFRadioGroup, PDFTextField, PDFWidgetAnnotation, degrees, drawImage } from 'pdf-lib'
import { PDFDocumentProxy, PDFPageProxy } from '..'
import { renderSignatureField } from './signatureField'
import { renderTextField } from './textField'
import { renderRadioField } from './radioField'
import { renderCheckboxField } from './checkboxField'
import { renderUnknownField } from './unknownField'

interface PageAnnotations {
  page: fabric.Object
  signatures: fabric.Object[]
  images: fabric.Object[]
  radiobuttons: fabric.Object[]
  checkboxes: fabric.Object[]
}

export interface FormField {
  index: number;
  type: 'text' | 'signature' | 'address' | 'markup' | 'radio' | 'checkbox';
  value: string;
  key: string;
  description: string;
  group?: string;
}

const groupByKey = (list: any[], key: string) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

export class PDFController {
  static pdfMarkupFields = new Array<any>()
  static canvasMarkupFields = new Array<any>()

  private static renderPDFPageToCanvas (page: PDFPageProxy, totalPages: number, currentPage: number, fields: Array<any> = []): Promise<HTMLCanvasElement> {
    return new Promise((resolve) => {
      // Retina scaling
      const viewport = page.getViewport({ scale: window.devicePixelRatio * 1.5 })

      const tr = new CoordsTranformer({ width: viewport.width, height: viewport.height }, { width: page.getViewport().viewBox[2], height: page.getViewport().viewBox[3] })
      const offset = viewport.height - ((viewport.height / totalPages) * (totalPages - currentPage))
      this.canvasMarkupFields = [...this.canvasMarkupFields, ...fields?.map(({ field, type }) => {
        const fields = field.map((f: any) => {
          const coord = viewport.convertToViewportPoint(f.x, (f.y) + f.height)

          return {
            left: coord[0],
            top: (coord[1] / 2) + (offset + (totalPages > 2 ? offset / currentPage : 0)) + (tr.translateLength(20) * currentPage),
            width: tr.translateLength(f.width),
            height: tr.translateLength(f.height),
            type
          }
        })

        return {
          fields,
          type
        }
      })
      ]

      // Prepare canvas using PDF page dimensions
      const pageCanvas = document.createElement('canvas')
      const context = pageCanvas.getContext('2d')
      pageCanvas.height = viewport.height
      pageCanvas.width = viewport.width

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }

      const renderTask = page.render(renderContext)

      return renderTask.promise.then(() => resolve(pageCanvas))
    })
  }

  static renderPDFPagesAsFabricImages (src: string, pdfFormFields: FormField[], signatureUrl: string): Promise<fabric.Image[]> {
    return new Promise((resolve) => {
      PDFController.prefillPDFDocument(src, pdfFormFields, signatureUrl).then((data) => {
        const loadingTask = window.pdfjsLib.getDocument({ data })
        loadingTask.promise.then(async (pdf: PDFDocumentProxy) => {
          // Get all pages
          let pageProxies = []
          for (let i = 1; i < pdf.numPages + 1; i++) {
            pageProxies.push(pdf.getPage(i))
          }
          pageProxies = await Promise.all(pageProxies)

          // Render to canvas
          let pageCanvases = []
          let i = 0
          /* let jumps = 0 */
          for (const pageProxy of pageProxies) {
            const fields = this.pdfMarkupFields?.filter(({ field, page, index }) => {
              return page === i
              /* const offset = 10 // arbitrary value
              if (this.pdfMarkupFields?.[index + 1] && this.pdfMarkupFields?.[index + 1].field.y > field.y + offset) {
                jumps++
                return jumps - 1 === i
              }

              return jumps === i */
            }).filter(({ type }) => type === 'markup' || type === 'radio' || type === 'checkbox')
            pageCanvases.push(this.renderPDFPageToCanvas(pageProxy, pdf.numPages, i, fields))
            i++
            /* jumps = 0 */
          }
          pageCanvases = await Promise.all(pageCanvases)

          // Convert to fabric.Image
          const pageFabicImages = pageCanvases.map(x => new fabric.Image(x))
          resolve(pageFabicImages)
        })
      })
    })
  }

  static async prefillPDFDocument (url: string, formFields: FormField[], signatureUrl: string): Promise<string> {
    const pdf = await this.getPDFDocument(url, formFields, signatureUrl)
    const pdfData = await pdf.saveAsBase64()
    return atob(pdfData)
  }

  static async getPDFDocument (url: string, formFields: FormField[], signatureUrl: string): Promise<PDFDocument> {
    const data = await fetch(url).then(res => res.arrayBuffer())
    const pdf = await PDFDocument.load(data)
    const form: PDFForm = pdf.getForm()
    const fields = form.getFields()

    const signature = signatureUrl ? await fetch(signatureUrl).then(res => res.arrayBuffer()) : undefined
    const pdfLibSigImg = signatureUrl ? await pdf.embedPng(signature as ArrayBuffer) : undefined

    const pages = pdf.getPages()
    const fieldsPerPage = pages.map((page) => (page.node.Annots()?.asArray() ?? []).length)
    let currentPage = 0
    let currentPagefields = 0
    const visitedIndex: number[] = []
    const relatedFormFields = groupByKey(formFields, 'group')

    fields.forEach((field, index) => {
      currentPagefields = currentPagefields + 1
      if (currentPagefields > fieldsPerPage[currentPage]) {
        currentPage = currentPage + 1
        currentPagefields = 1
      }

      if (!visitedIndex.includes(index)) {
        const formField = formFields?.find((f) => f.index === index)
        const rFormFields: any[] = formField?.group && relatedFormFields[formField?.group]
          ? relatedFormFields[formField?.group]
          : formField ? [formField] : []
        rFormFields.forEach((r: FormField) => {
          visitedIndex.push(r.index)
        })
        const rFields = rFormFields.map((rField: FormField) => fields[rField.index])
        const widgets: PDFWidgetAnnotation[] = rFields?.flatMap((f: PDFField) => f.acroField?.getWidgets())

        /* const formFields = formField?.group && relatedFormFields[formField?.group]
        ? relatedFormFields[formField?.group]?.flatMap(({ index }: { index: number }) => fields[index].acroField?.getWidgets())
        : [field] */

        /* const widgets: PDFWidgetAnnotation[] = formField?.group && relatedFormFields[formField?.group]
          ? relatedFormFields[formField?.group]?.flatMap(({ index }: { index: number }) => fields[index].acroField?.getWidgets())
          : field?.acroField?.getWidgets() */

        this.pdfMarkupFields.push({ field: widgets?.map((widget) => widget.getRectangle()), index, type: formField?.type ?? 'text', page: currentPage })

        const toRenderFields: any[] = rFormFields.length > 0 ? rFormFields : [field]
        toRenderFields.forEach((toRenderField: FormField) => {
          // Document is being signed
          if (signatureUrl) {
            switch (toRenderField?.type) {
              case undefined:
                renderUnknownField(field as PDFTextField)
                break
              case 'signature':
                renderSignatureField(field as PDFTextField, pdfLibSigImg as PDFImage)
                break
              case 'address':
                renderTextField(field as PDFTextField, `${toRenderField?.value ?? ''}`, 11)
                break
              case 'radio':
                renderRadioField(field as PDFRadioGroup, toRenderField?.value)
                break
              case 'checkbox':
                renderCheckboxField(field as PDFCheckBox, toRenderField?.value)
                break
              default:
                renderTextField(field as PDFTextField, `${toRenderField?.value ?? ''}`, toRenderField.key === 'information' ? 8 : 11)
            }
          }
        })
      }
    })

    const pdfBytes = await pdf.save()

    return await PDFDocument.load(pdfBytes)
  }

  static async getPDFImage (pdfDoc: PDFDocument, url: string): Promise<PDFImage> {
    const data = await fetch(url).then(res => res.arrayBuffer())
    return pdfDoc.embedPng(data)
  }

  static async mergeAnnotations (pdfDoc: PDFDocument, canvas: fabric.Canvas): Promise<PDFDocument> {
    const annotationGroup = this.getAnnotationsGroupByPage(canvas)

    for (let i = 0; i < pdfDoc.getPageCount(); i++) {
      const pdfPage = pdfDoc.getPage(i)
      const fabricPage = annotationGroup[i].page
      const pageSignatures = annotationGroup[i].signatures
      const pageImages = annotationGroup[i].images
      const pageRadiobuttons = annotationGroup[i].radiobuttons
      const pageCheckboxes = annotationGroup[i].checkboxes

      const tr = new PDFDimensionTransformer(fabricPage as fabric.Image, pdfPage)
      for (const sig of pageSignatures) {
        const pdfImage = await this.getPDFImage(pdfDoc, sig.toDataURL({}))
        const coords = tr.getPDFCoords(sig)
        pdfPage.drawImage(
          pdfImage,
          {
            x: coords.x,
            y: coords.y - tr.getPDFLength(pdfImage.height),
            width: tr.getPDFLength(pdfImage.width),
            height: tr.getPDFLength(pdfImage.height)
          }
        )
      }

      for (const img of pageImages) {
        const pdfImage = await this.getPDFImage(pdfDoc, img.toDataURL({}))
        const coords = tr.getPDFCoords(img)
        pdfPage.drawImage(
          pdfImage,
          {
            x: coords.x,
            y: coords.y - tr.getPDFLength(pdfImage.height),
            width: tr.getPDFLength(pdfImage.width),
            height: tr.getPDFLength(pdfImage.height)
          }
        )
      }

      for (const radiobutton of pageRadiobuttons) {
        const pdfRadiobutton = await this.getPDFImage(pdfDoc, radiobutton.toDataURL({}))
        const coords = tr.getPDFCoords(radiobutton)
        pdfPage.drawImage(
          pdfRadiobutton,
          {
            x: coords.x,
            y: coords.y - tr.getPDFLength(pdfRadiobutton.height),
            width: tr.getPDFLength(pdfRadiobutton.width),
            height: tr.getPDFLength(pdfRadiobutton.height)
          }
        )
      }

      for (const checkbox of pageCheckboxes) {
        const pdfRadiobutton = await this.getPDFImage(pdfDoc, checkbox.toDataURL({}))
        const coords = tr.getPDFCoords(checkbox)
        pdfPage.drawImage(
          pdfRadiobutton,
          {
            x: coords.x,
            y: coords.y - tr.getPDFLength(pdfRadiobutton.height),
            width: tr.getPDFLength(pdfRadiobutton.width),
            height: tr.getPDFLength(pdfRadiobutton.height)
          }
        )
      }
    }

    return pdfDoc
  }

  static getAnnotationsGroupByPage (canvas: fabric.Canvas): PageAnnotations[] {
    const objs = canvas.getObjects() as FabricObject[]
    const pages = objs.filter(x => _.get(x, 'attrs.type') === 'pdf-page')
    const signatures = objs.filter(x => _.get(x, 'attrs.type') === 'signature')
    const images = objs.filter(x => _.get(x, 'attrs.type') === 'image')
    const radiobuttons = objs.filter(x => _.get(x, 'attrs.type') === 'radiobutton')
    const checkboxes = objs.filter(x => _.get(x, 'attrs.type') === 'checkbox')

    const results = []
    for (const i in pages) {
      const page = pages[i]

      results.push({
        page_num: i,
        page: page,
        signatures: signatures.filter(s => s.isContainedWithinObject(page, true)),
        images: images,
        radiobuttons,
        checkboxes
      })
    }

    return results
  }
}
