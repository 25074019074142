import { render, staticRenderFns } from "./locale.vue?vue&type=template&id=e489a2b6&"
import script from "./locale.vue?vue&type=script&lang=ts&"
export * from "./locale.vue?vue&type=script&lang=ts&"
import style0 from "./locale.vue?vue&type=style&index=0&id=e489a2b6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports