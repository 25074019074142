
import { useAppStore } from '@/store/app'
import { mdiMagnify } from '@mdi/js'
import Vue from 'vue'
import { Template } from '@/types/template'
import PdfViewer from './pdf-viewer.vue'

export default Vue.extend({
  name: 'Templates',
  components: {
    PdfViewer
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      showRenameDialog: false,
      showInspectDialog: false,
      mdi: {
        mdiMagnify
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      templates: new Array<Template>(),
      toRename: {} as Template,
      validForm: true,
      newName: '',
      toView: {} as Template
    }
  },
  async mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${client}/templates/`)
      const data = await res.json()
      const templates = Object.keys(data).map(id => data[id])

      this.templates = templates
      this.loading = false
    },
    renameTemplate (template: Template) {
      this.toRename = template
      this.showRenameDialog = true
    },
    async confirmRenameTemplate () {
      this.loading = true
      this.closeDialog()
      const client = this.app.selectedClient?.id ?? ''
      const formData = {
        name: this.newName
      }

      await fetch(`${process.env.VUE_APP_API_URL}/${client}/templates/${this.toRename.id}/name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      this.toRename = {} as Template
      (this.$refs.form as any)?.reset()

      this.fetch()
    },
    cancelRenameTemplate () {
      (this.$refs.form as any)?.reset()
      this.closeDialog()
    },
    viewTemplate (template: Template) {
      this.toView = template
      this.showInspectDialog = true
    },
    closeDialog () {
      this.showRenameDialog = false
      this.showInspectDialog = false
      this.toView = {} as Template
    }
  }
})
