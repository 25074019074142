
import { useAppStore } from '@/store/app'
import { getInitials } from '@/utils/name'
import Vue from 'vue'
import { Requester } from './pdf-signer.vue'

export default Vue.extend({
  name: 'RequesterLookup',
  props: {
    requesterId: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      required: false
    }
  },
  watch: {
    async requesterId () {
      this.value = this.requesters.find((requester) => requester.id === this.requesterId)
    }
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      requesters: new Array<any>(),
      value: null
    }
  },
  async mounted () {
    this.emitInterface()
    await this.fetch()
    this.autoSelect()
  },
  methods: {
    getInitials (name: string) {
      return getInitials(name)
    },
    filterData (item: any, queryText: string) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.id.toLowerCase().includes(queryText.toLowerCase())
      )
    },
    changeValue (value: { name: string; id: string } | null) {
      this.$emit('changed', value ? value.id : null)
    },
    remove (item: { name: string; }) {
      this.requesters = []
      /*
        const index = this.friends.indexOf(item.name)
        if (index >= 0) this.friends.splice(index, 1)
      */
    },
    clear () {
      if (this.requesters.length > 1) {
        this.value = null
      }
    },
    async fetch () {
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/requesters`)
      const data = await res.json()
      const requesters = Object.keys(data).map(id => data[id])
        .sort((r1: {name: string}, r2: {name: string}) => (r1.name < r2.name) ? -1 : (r1.name > r2.name) ? 1 : 0)

      this.requesters = requesters.filter((doc) => !doc.inactive)
    },
    autoSelect () {
      const enabledRequesters = this.requesters.filter(requester => requester.signatureUrl)
      if (enabledRequesters.length === 1) {
        this.value = enabledRequesters[0]
        this.changeValue(enabledRequesters[0])
      }
    },
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface () {
      this.$emit('interface', {
        clear: () => this.clear(),
        refetch: async () => this.fetch()
      })
    }
  }
})
