
import Vue, { PropOptions } from 'vue'
import xIcon from './x-icon.vue'

export default Vue.extend({
  components: {
    xIcon
  },

  props: {
    icon: {
      type: String
    } as PropOptions<string>,
    iconSize: {
      type: Number,
      default: 24
    },
    text: {
      type: String,
      required: false,
      default: ''
    } as PropOptions<string>,
    color: {
      type: String,
      default: '#000000'
    } as PropOptions<string>,
    autoHideText: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: '#f5f5f5'
    } as PropOptions<string>,
    disabled: {
      type: Boolean
    }
  },

  computed: {
    showButtonText (): boolean {
      if (!this.autoHideText) {
        return true
      }
      return window.innerWidth > 550
    }
  }
})
