
import { useAppStore } from '@/store/app'
import { mdiArrowRightCircle } from '@mdi/js'
import Vue from 'vue'
import Loading from './common/loading.vue'
import SaveChanges from './common/save-changes.vue'
import xIcon from './common/x-icon.vue'
import ApplicationSVG from './svg/application.vue'
import FinishedSVG from './svg/finished.vue'
import SignatureSVG from './svg/signature.vue'

export default Vue.extend({
  name: 'Theme',
  components: {
    ApplicationSVG,
    FinishedSVG,
    SignatureSVG,
    xIcon,
    SaveChanges,
    Loading
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      initialColor: '',
      color: '',
      previewUrl: '',
      file: null,
      mdi: {
        mdiArrowRightCircle
      },
      loading: false
    }
  },
  beforeMount () {
    this.initialColor = this.app.primaryColor
    this.color = this.initialColor
    this.previewUrl = this.app.selectedClient?.logo ?? ''
  },
  methods: {
    setColor () {
      this.app.setPrimaryColor(this.color)
      this.initialColor = this.color
    },
    previewImage () {
      if (this.file) {
        this.previewUrl = URL.createObjectURL(this.file)
      } else {
        this.previewUrl = this.app.selectedClient?.logo ?? ''
      }
    },
    cancel () {
      this.color = this.initialColor
      this.file = null
      this.previewImage()
    },
    async save () {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      if (this.color !== this.initialColor) {
        const formData = {
          color: this.color
        }

        await fetch(`${process.env.VUE_APP_API_URL}/${client}/settings/theme`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })

        this.app.setPrimaryColor(this.color)
      }

      if (this.file) {
        const formData = new FormData()
        formData.append('file', this.file)

        await fetch(`${process.env.VUE_APP_API_URL}/${client}/upload/logo`, {
          method: 'POST',
          body: formData
        })

        this.file = null
        this.app.refreshLogo()
      }

      this.loading = false
    }
  },
  watch: {
    'app.primaryColor' (newValue, oldValue) {
      this.initialColor = this.app.primaryColor
    }
  }
})
