
import { getOptCookies, optCookies, optedForCookiesBefore } from '@/utils/storage'
import Vue from 'vue'
import { bootstrap } from 'vue-gtag'

export default Vue.extend({
  name: 'CookieBanner',
  data: () => ({
    cookieBanner: false
  }),
  beforeMount () {
    const optedBefore = optedForCookiesBefore()
    this.cookieBanner = !optedBefore

    if (optedBefore) {
      const optIn = getOptCookies()

      if (optIn) {
        bootstrap().then((_) => {
          //
        })
      }
    }
  },
  methods: {
    acceptCookies () {
      bootstrap().then((_) => {
        optCookies(true)
        this.cookieBanner = false
      })
    },
    declineCookies () {
      optCookies(false)
      this.cookieBanner = false
    }
  }
})
