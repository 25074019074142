
import { useAppStore } from '@/store/app'
import { Document } from '@/types/document'
import { VForm } from '@/types/form'
import { Signer } from '@/types/signer'
import { getInitials } from '@/utils/name'
import Vue from 'vue'

export default Vue.extend({
  name: 'signers',
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      signers: new Array<Document>(),
      showInspectDialog: false,
      toEdit: {} as Signer,
      showConfirmDialog: false,
      toDelete: {} as { email: string; reference: string; },
      validForm: true,
      rules: {
        required: (value: string) => !!value || this.$t('formValidation.required'),
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('formValidation.invalidEmail')
        },
        date: (value: string) => {
          const t = new Date(value)
          // @ts-expect-error: blah
          return (t instanceof Date && isFinite(t)) || this.$t('formValidation.invalidDate')
        }
      }
    }
  },
  async mounted () {
    this.fetch()
  },
  methods: {
    getInitials (name: string) {
      return getInitials(name)
    },
    async fetch () {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${client}/signers`)
      const signers = await res.json()

      this.signers = Object.values(signers)
      this.loading = false
    },
    filterOnlyCapsText (value: any, search: string, item: Signer) {
      return value &&
        search &&
        item &&
        (
          `${value ?? ''}`.includes(search) ||
          item?.email?.includes(search) ||
          item?.name?.includes(search) ||
          item?.lastname?.includes(search) ||
          item?.id?.includes(search) ||
          item?.phone?.includes(search) ||
          item?.historyNumber?.includes(search)
        )
    },
    closeDialog () {
      this.showInspectDialog = false
      this.toEdit = {} as Signer
      this.showConfirmDialog = false
      this.toDelete = {} as { email: string; reference: string }
    },
    edit (signer: Signer) {
      this.toEdit = signer
      this.showInspectDialog = true
    },
    async configureSigner (signer: Signer) {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${client}/signers/${signer.email}/configure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      this.loading = false
      console.log(response)
    },
    async save () {
      if (!await (this.$refs.form as VForm).validate()) {
        return
      }

      const formData = {
        id: this.toEdit.id?.trim(),
        name: this.toEdit.name?.trim(),
        lastname: this.toEdit.lastname?.trim(),
        email: this.toEdit.email?.trim(),
        historyNumber: this.toEdit.historyNumber?.trim(),
        birthday: this.toEdit.birthday?.trim(),
        gender: this.toEdit.gender?.trim(),
        phone: this.toEdit.phone?.trim(),
        address: this.toEdit.address?.trim(),
        preferredLang: this.toEdit.preferredLang?.trim()
      }

      const user = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${user}/signers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      this.fetch()
      this.closeDialog()
    },
    redirect (signer: string) {
      this.$router.push({ name: 'Documents', params: { signer } })
    },
    deleteSelfRegistry (selfRegistry: { email: string; reference: string }) {
      this.toDelete = selfRegistry
      this.showConfirmDialog = true
    },
    async confirmDeleteSelfRegistry () {
      const client = this.app.selectedClient?.id ?? ''
      await fetch(`${process.env.VUE_APP_API_URL}/${client}/signers/${this.toDelete.email}/registry/${this.toDelete.reference}`, {
        method: 'DELETE'
      })
      this.closeDialog()
      this.fetch()
    }
  }
})
