export enum Locales {
  ES = 'es',
  CAT = 'cat',
  EN = 'en'
}

export const LOCALES = [
  { value: Locales.ES, caption: 'Español' },
  { value: Locales.CAT, caption: 'Catalá' },
  { value: Locales.EN, caption: 'English' }
]
