
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'SignaturePad',
  props: {
    value: {
      type: null as unknown as PropType<string | null>,
      required: true
    },
    customControls: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        penColor: 'black'
      }
    }
  },
  async mounted () {
    this.emitInterface()
  },
  methods: {
    clear () {
      (this.$refs.signaturePad as any).undoSignature()
    },
    cancel () {
      (this.$refs.signaturePad as any).undoSignature()
      this.$emit('cancel')
    },
    submit () {
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature()

      this.$emit('input', isEmpty ? null : data)
      this.$emit('submit')
    },
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface () {
      this.$emit('interface', {
        submit: () => this.submit()
      })
    }
  }
})
