import { Locales } from './locales'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cat from './locales/cat.json'
import en from './locales/en.json'
import es from './locales/es.json'

export const messages = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.CAT]: cat
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  messages,
  locale: Locales.ES,
  fallbackLocale: Locales.ES
})

export default i18n
