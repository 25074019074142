
import { useAppStore } from '@/store/app'
import Vue from 'vue'
import NewSigner from '../components/new-signer.vue'
import SignerLookup from '../components/signer-lookup.vue'
import RequestSignature from '../components/request-signature/request-signature.vue'

export default Vue.extend({
  name: 'Home',
  components: { SignerLookup, NewSigner, RequestSignature },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      pictureUrl: '',
      initials: '',
      selectedSigner: {},
      reduced: false,
      addSignerFlow: false,
      signatureFlow: false,
      childInterface: {
        clear: () => Object,
        refetch: async () => Object
      },
      waiting: 0,
      signed: 0,
      revoked: 0,
      loading: true
    }
  },
  watch: {
    $route (to, from) {
      this.closeActions()
      this.childInterface.refetch()
      this.getStats()
    }
  },
  beforeMount () {
    const displayName = this.app.customer?.name ?? ''
    this.pictureUrl = this.app.selectedClient?.pictureUrl ?? ''
    this.initials = this.app.initials
    this.getStats()
  },
  methods: {
    // Setting the interface when emitted from child
    getChildInterface (childInterface: any) {
      this.childInterface = childInterface
    },
    signerChanged (signer: string | null) {
      this.selectedSigner = signer ?? {}
    },
    addingSignerFlow () {
      this.reduced = true
      this.selectedSigner = {}
      this.addSignerFlow = true
      this.signatureFlow = false
      this.childInterface.clear()
    },
    async signerAdded (signer: { id?: string } | null) {
      await this.childInterface.refetch()
      if (signer?.id) {
        this.selectedSigner = signer ?? {}
        this.requestingSignatureFlow()
      } else {
        this.closeActions()
      }
    },
    async requestingSignatureFlow () {
      this.reduced = true
      this.addSignerFlow = false
      this.signatureFlow = true
    },
    closeActions () {
      this.reduced = false
      this.addSignerFlow = false
      this.signatureFlow = false
      this.selectedSigner = {}
      this.childInterface.clear()
    },
    async getStats () {
      this.loading = true
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/stats`)
      const stats = await res.json()

      this.waiting = stats.waiting
      this.signed = stats.signed
      this.revoked = stats.revoked
      this.loading = false
    },
    signatureRequested () {
      this.getStats()
    }
  }
})
