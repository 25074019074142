
import { useAppStore } from '@/store/app'
import { User, Role } from '@/types/user'
import { mdiMagnify } from '@mdi/js'
import Vue from 'vue'
import { getInitials } from '@/utils/name'
import { randomColor } from '@/utils/colors'
import SaveChanges from './common/save-changes.vue'

export default Vue.extend({
  name: 'UserManagement',
  components: {
    SaveChanges
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      selected: [],
      toDeactivate: new Array<User>(),
      toReactivate: new Array<User>(),
      showDeactivateDialog: false,
      showReactivateDialog: false,
      mdi: {
        mdiMagnify
      },
      headers: [
        { text: 'Name', value: 'displayName' },
        { text: 'Roles', value: 'role' },
        { text: 'Status', value: 'configured' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      users: new Array<User>(),
      activeUsers: new Array<User>(),
      inactiveUsers: new Array<User>(),
      showingActive: true,
      toEdit: {} as User,
      addUserDialog: false,
      newUser: {
        name: '',
        email: '',
        role: null,
        userId: ''
      },
      validForm: true
    }
  },
  async mounted () {
    this.fetch()
  },
  methods: {
    getInitials (name: string) {
      return getInitials(name)
    },
    signatureRequestActive (user: User) {
      return user.role?.includes('basic') && user.active
    },
    async fetch () {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${client}/users/all`)
      const data = await res.json()
      const users = Object.keys(data).map(id => data[id]).map((user) => ({
        ...user,
        color: randomColor()
      }))

      this.activeUsers = users.filter((user) => user.active)
      this.inactiveUsers = users.filter((user) => !user.active)

      this.users = JSON.parse(JSON.stringify(this.activeUsers))
      this.loading = false
    },
    async configureSignature (user: User) {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${client}/users/${user.id}/configure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      this.loading = false
      console.log(response)
    },
    reactivate (user: User) {
      this.toReactivate = [user]
      this.showReactivateDialog = true
    },
    deactivate (user: User) {
      this.toDeactivate = [user]
      this.showDeactivateDialog = true
    },
    async deactivateConfirm () {
      const client = this.app.selectedClient?.id ?? ''
      const toDeactivate = this.toDeactivate[0]
      this.closeDialog()
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${client}/users/${toDeactivate.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      this.fetch()
    },
    async reactivateConfirm () {
      const client = this.app.selectedClient?.id ?? ''
      const toReactivate = this.toReactivate[0]
      this.closeDialog()
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${client}/users/${toReactivate.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      this.fetch()
    },
    closeDialog () {
      this.showDeactivateDialog = false
      this.showReactivateDialog = false
      this.toDeactivate = []
      this.toReactivate = []
    },
    showActive () {
      this.users = JSON.parse(JSON.stringify(this.activeUsers))
      this.showingActive = true
    },
    showInactive () {
      this.users = JSON.parse(JSON.stringify(this.inactiveUsers))
      this.showingActive = false
      this.cancel()
    },
    modifyRoles (user: User) {
      this.toEdit = user
    },
    isBeingEdited (user: User) {
      return this.toEdit?.email === user.email
    },
    deleteRole (role: Role) {
      if (this.toEdit?.role?.length === 1) {
        return
      }
      this.toEdit.role = this.toEdit?.role?.filter((r) => r !== role)
    },
    getApplicableRoles (user: User) {
      const roles = ['basic', 'global', 'admin']
      return roles.filter((role) => !user.role?.includes(role as Role))
    },
    addRole (role: string) {
      this.toEdit.role = [...this.toEdit?.role as Role[], role as Role]
    },
    hasChanged (user: User) {
      if (!this.showingActive) {
        return false
      }

      const originalUser = this.activeUsers.find((oUser) => oUser.email === user.email)

      return !this.areEqual(originalUser?.role, user.role)
    },
    areEqual (a1?: Role[], a2: Role[] = []) {
      if (a1?.length !== a2?.length) {
        return false
      }

      const missing = a1.filter(item => a2.indexOf(item) < 0)
      return missing.length === 0
    },
    cancel () {
      this.users = JSON.parse(JSON.stringify(this.showingActive ? this.activeUsers : this.inactiveUsers))
      this.toEdit = {} as User
    },
    save () {
      this.toEdit = {} as User
      const changedRoles = this.activeUsers.filter((currentUser: User, index: number) => {
        return !this.areEqual(this.users[index]?.role as Role[], currentUser?.role)
      })
      this.fetch()
    },
    startAddingUser () {
      this.addUserDialog = true
    },
    closeAddUser () {
      this.addUserDialog = false
      this.newUser = {
        name: '',
        email: '',
        role: null,
        userId: ''
      }
    },
    async addUser () {
      (this.$refs.form as HTMLFormElement).validate()

      if (!this.validForm) {
        return
      }

      this.addUserDialog = false
      const formData = {
        displayName: this.newUser.name,
        userId: this.newUser.userId,
        email: this.newUser.email,
        role: [this.newUser.role]
      }

      const client = this.app.selectedClient?.id ?? ''
      const response = await fetch(`${process.env.VUE_APP_API_URL}/${client}/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      this.fetch()

      this.newUser = {
        name: '',
        email: '',
        role: null,
        userId: ''
      }
    }
  },
  computed: {
    unsavedChanges (): boolean {
      if (this.activeUsers?.length === 0 || !this.showingActive) {
        return false
      }

      const t = this.activeUsers.map((currentUser: User, index: number) => {
        if (this.users[index]?.role?.length === 0) {
          return false
        }

        return this.areEqual(this.users[index]?.role as Role[], currentUser?.role)
      })

      return !t.every(Boolean)
    }
  }
})
