import { fabric } from 'fabric'
import { Field, FieldElement, MAX_HEIGHT, MAX_WIDTH } from '.'
import { mdiArrowCollapseDown } from '@mdi/js'

export const createSignatureField = (fieldGroup: Field[], index: number, signatureLabel: string, onClick: (event: fabric.IEvent<Event>) => void) => {
  const field = fieldGroup[0]
  const width = Math.min(field.width, MAX_WIDTH)
  const height = Math.min(field.height, MAX_HEIGHT)
  const text = new fabric.Text(signatureLabel, {
    originX: 'center',
    originY: 'center',
    left: 0.5 * width,
    top: (height / 2) - 15,
    fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
    fontSize: 30
  })

  const path = new fabric.Path(mdiArrowCollapseDown, {
    originX: 'center',
    originY: 'center',
    left: 0.5 * width,
    top: (height / 2) + 15
  })

  const shadow = new fabric.Shadow({
    color: 'gray',
    blur: 5
  })

  // Render signature fields
  const rect = new fabric.Rect({
    stroke: 'transparent',
    fill: '#FFc423',
    width,
    height,
    rx: 10,
    ry: 10,
    shadow
  })

  const bottom = field.top + field.height
  const rest = bottom - height
  const t = rest - field.top

  const group = new fabric.Group([rect, text, path], {
    name: 'signature',
    left: field.left,
    top: 2 * field.top + (t / 2),
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingFlip: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
    hasRotatingPoint: false,
    hasControls: false,
    hasBorders: false
  }) as FieldElement

  group.id = `signature-${Date.now()}`
  group.groupId = `group-${index}`
  group.on('mousedown', (event: fabric.IEvent<Event>) => {
    onClick(event)
  })

  return group
}
