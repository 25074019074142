
import { primary } from '@/constants'
import { DocumentStatus } from '@/types/document'
import { mdiFileDocumentArrowRightOutline, mdiFileDocumentCheckOutline, mdiFileDocumentOutline, mdiFileDocumentRemoveOutline } from '@mdi/js'
import Vue, { PropOptions } from 'vue'
import DocumentIndicator from './document-indicator.vue'

const statuses: Record<DocumentStatus, { background: string; color: string; }> = {
  signed: {
    background: '#eef6ee',
    color: '#55a365'
  },
  waiting: {
    background: '#fff8e6',
    color: '#ffb800'
  },
  revoked: {
    background: '#fff0f0',
    color: '#ef5a5a'
  },
  unset: {
    background: '#e4dbff',
    color: primary
  }
}

export default Vue.extend({
  name: 'DocumentsPreviewItem',
  components: { DocumentIndicator },
  props: {
    status: {
      type: String,
      required: true
    } as PropOptions<'signed' | 'revoked' | 'waiting'>,
    title: {
      type: String,
      required: true
    },
    timestamp: {
      type: Number
    },
    multiline: {
      type: Boolean,
      default: false
    },
    url: {
      type: String
    },
    showViewAction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      total: 0,
      mdi: {
        waiting: mdiFileDocumentArrowRightOutline,
        signed: mdiFileDocumentCheckOutline,
        revoked: mdiFileDocumentRemoveOutline,
        unset: mdiFileDocumentOutline
      }
    }
  },
  computed: {
    color () {
      const status = this.status
      return statuses[status]?.color
    },
    background () {
      const status = this.status
      return statuses[status]?.background
    },
    actionTime () {
      if (!this.timestamp) {
        return undefined
      }

      const timestamp = this.timestamp as number
      const utcDate = new Date(timestamp)

      const time = utcDate.toLocaleTimeString().split(':')

      return `${utcDate.toLocaleDateString()} ${time[0]}:${time[1]}`
    }
  },
  methods: {
    viewDocument (title: string, url: string) {
      this.$emit('viewDocument', { name: title, url: url })
    }
  }
})
