import { PDFDict, PDFRadioGroup, PDFRef } from 'pdf-lib'

export const renderRadioField = (pdfRadioGroup: PDFRadioGroup, value?: string) => {
  const widgets = pdfRadioGroup.acroField.getWidgets()
  widgets.forEach(widget => {
    // console.log(widget.getOrCreateAppearanceCharacteristics().getBorderColor())
    widget.getOrCreateAppearanceCharacteristics().setBackgroundColor([1, 0, 0])
    // console.log(widget.getOrCreateAppearanceCharacteristics().getBorderColor())
  })

  if (value) {
    pdfRadioGroup.select(value)
  }

  pdfRadioGroup.enableReadOnly()
}
