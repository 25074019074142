
import Vue from 'vue'
import Locale from './locale.vue'

export default Vue.extend({
  name: 'Footer',
  components: {
    Locale
  }
})
