import VueRouter from 'vue-router'
import { accessToken, reset } from './utils/storage'

const noAuthRoutes = ['/:clientId/actions/:actionId']

const { fetch: originalFetch } = window

export const intercept = async (router: VueRouter, ...args: any) => {
  const [resource, originalConfig] = args

  const token = accessToken()
  const config = token ? { ...originalConfig ?? {}, headers: { ...originalConfig?.headers ?? {}, Authorization: `Bearer ${token}` } } : originalConfig

  // request interceptor here
  const response = await originalFetch(resource, config)

  if (response.status === 401) {
    const loginpath = `${window.location.pathname}${window.location.search}`
    router.push({ name: 'Root', query: { from: loginpath } }).catch(err => err)
    reset()
  }

  if (response.status === 403) {
    router.push({ name: '401' })
  }

  return response
}
