export const getInitials = (displayName?: string) => {
  if (!displayName) {
    return ''
  }

  const parts = displayName.trim().split(' ')
  const initials = parts.length === 1
    ? `${parts[0][0]}${parts[0][1]}`
    : `${parts[0][0]}${parts[1][0]}`

  return initials
}
