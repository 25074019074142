import i18n from '@/i18n'
import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueSignature from 'vue-signature-pad'
import App from './App.vue'
import { intercept } from './interceptor'
import pinia from './plugins/pinia'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_MEASUREMENT_ID },
  bootstrap: false
}, router)

Vue.use(VueSignature)
Vue.config.productionTip = false

window.fetch = async (...args) => intercept(router, ...args)

new Vue({
  router,
  i18n,
  vuetify,
  pinia,
  render: h => h(App)
})
  .$mount('#app')
